<template>
  <p v-if="type === 'powerAndGas'">
    Ich bin mit den
    <a :href="documentLinks.agbPower" target="_blank">AGB (Strom)</a>
    und
    <a :href="documentLinks.agbGas" target="_blank">AGB (Gas)</a>
    einverstanden. Polarstern darf, soweit erforderlich, meinen derzeitigen
    Gas-/Stromliefervertrag kündigen und die für meine Gas-/Stromlieferung nötigen
    Verträge mit dem zuständigen Netzbetreiber schließen. Hier geht es zu unseren
    <TheLink :to="config.routes.privacyPage" target="_blank">
      Datenschutzhinweisen
    </TheLink>
    und unserer
    <a :href="documentLinks.widerrufsbelehrungPower" target="_blank">
      Widerrufsbelehrung (Strom)
    </a>
    und
    <a :href="documentLinks.widerrufsbelehrungGas" target="_blank">
      Widerrufsbelehrung (Gas) </a
    >.
  </p>

  <p v-else-if="type === 'gas'">
    Ich bin mit den
    <a :href="documentLinks.agbGas" target="_blank">AGB</a>
    einverstanden. Polarstern darf, soweit erforderlich, meinen derzeitigen
    Gasliefervertrag kündigen und die für meine Gaslieferung nötigen Verträge mit dem
    zuständigen Netzbetreiber schließen. Hier geht es zu unseren
    <TheLink :to="config.routes.privacyPage" target="_blank">
      Datenschutzhinweisen
    </TheLink>
    und unserer
    <a :href="documentLinks.widerrufsbelehrungGas" target="_blank">Widerrufsbelehrung</a>.
  </p>

  <p v-else-if="type === 'chargingCards'">
    Ich habe die
    <a :href="documentLinks.agbChargingCards" target="_blank">AGB</a>, die
    <a :href="documentLinks.widerrufsbelehrungChargingCards" target="_blank">
      Widerrufsbelehrung samt Widerrufsformular
    </a>
    und die
    <TheLink :to="config.routes.privacyPage" target="_blank">Datenschutzhinweise</TheLink>
    gelesen und bin damit einverstanden.
  </p>

  <p v-else>
    Ich bin mit den
    <a :href="documentLinks.agbPower" target="_blank">AGB</a>
    einverstanden. Polarstern darf, soweit erforderlich, meinen derzeitigen
    Stromliefervertrag kündigen und die für meine Stromlieferung nötigen Verträge mit dem
    zuständigen Netzbetreiber schließen. Hier geht es zu unseren
    <TheLink :to="config.routes.privacyPage" target="_blank">
      Datenschutzhinweisen
    </TheLink>
    und unserer
    <a :href="documentLinks.widerrufsbelehrungPower" target="_blank">
      Widerrufsbelehrung</a
    >.
  </p>
</template>

<script setup lang="ts">
import config from "~/config";
import type { ContractType } from "~/src/generated-sources/public";
import TheLink from "~/components/01_atoms/TheLink.vue";
const { documentLinks } = config;

export type ConsentType =
  | ContractType
  | "chargingCards"
  | "powerAndGas"
  | "tenantPower"
  | "tenantPowerShadow";

defineProps<{
  type: ConsentType;
}>();
</script>
